import React, { createContext, useEffect, useState } from 'react';

import { IBannerData, IFeatureFlags, IGlobal, IMessageModal } from '@interfaces/global';
import { IProductAndPlanData } from '../types/global';
import { getCookie } from '@services/cookies';
import { COOKIES } from '@constants/cookies';
import { ERROR_MESSAGES } from '@constants/errorMessages';
import { SIDEBAR_VIEWS } from '@components/sidebar/constants';

export const GlobalContext = createContext<IGlobal>({} as IGlobal);

interface IProps {
  children: JSX.Element;
}

export const StoreProvider = ({ children }: IProps) => {
  const [showBasket, setShowBasket] = useState(false);
  const [showCrossSellSingle, setShowCrossSellSingle] = useState(false);
  const [hasUserAddedProductToBasket, setHasUserAddedProductToBasket] = useState(false);
  const [showAddCoupon, setShowAddCoupon] = useState(false);
  const [showSpecialRequirementCoupon, setShowSpecialRequirementCoupon] = useState(false);
  const [specialRequirementCoupon, setSpecialRequirementCoupon] = useState({
    errorType: '',
    errorMessage: '',
  });
  const [showChangeCurrency, setShowChangeCurrency] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckLoginTried, setIsCheckLoginTried] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [bannerData, setBannerData] = useState<IBannerData | null>(null);
  const [bannerType, setBannerType] = useState('');
  const [utmModalData, setUtmModalData] = useState<any>(null);
  const [showGoalChange, setShowGoalChange] = useState(false);
  const [showStickyNav, setShowStickyNav] = useState(false);
  const [scrollPrevPos, setScrollPrevPos] = useState(-1);
  const [windowIsScrolled, setWindowIsScrolled] = useState(false);
  const [currencyCode, setCurrencyCode] = useState(getCookie(COOKIES.currency));
  const [appHeight, setAppHeight] = useState(0);
  const [showWarningScreen, setShowWarningScreen] = useState(false);
  const [warningScreenContent, setWarningScreenContent] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showPurchaseAgreement, setShowPurchaseAgreement] = useState(false);
  const [messageModal, setMessageModal] = useState({} as IMessageModal);
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlags | null>(null);
  const [sidebar, setSidebar] = useState<string>(SIDEBAR_VIEWS.empty);
  const [showShopModal, setShowShopModal] = useState(false);
  const [
    currentProductAndPlanData,
    setCurrentProductAndPlanData,
  ] = useState<IProductAndPlanData | null>(null);
  const [filterName, setFilterName] = useState('');
  const [userKitStatus, setUserKitStatus] = useState<any>(null);

  const setErrorModalMessage = (message?: string) =>
    setMessageModal({
      visible: true,
      title: 'Oops, something went wrong',
      message: message ? message : ERROR_MESSAGES.generic,
      buttonLabel: 'OK',
    });

  const globalContext: IGlobal = {
    windowIsScrolled,
    setWindowIsScrolled,
    showStickyNav,
    setShowStickyNav,
    scrollPrevPos,
    setScrollPrevPos,
    bannerType,
    setBannerType,
    isLoggedIn,
    setIsLoggedIn,
    isCheckLoginTried,
    setIsCheckLoginTried,
    showBasket,
    setShowBasket,
    showAddCoupon,
    setShowAddCoupon,
    showCrossSellSingle,
    setShowCrossSellSingle,
    hasUserAddedProductToBasket,
    setHasUserAddedProductToBasket,
    showChangeCurrency,
    setShowChangeCurrency,
    bannerData,
    setBannerData,
    bannerHeight,
    setBannerHeight,
    utmModalData,
    setUtmModalData,
    showGoalChange,
    setShowGoalChange,
    currencyCode,
    setCurrencyCode,
    showSpecialRequirementCoupon,
    setShowSpecialRequirementCoupon,
    specialRequirementCoupon,
    setSpecialRequirementCoupon,
    appHeight,
    setAppHeight,
    showWarningScreen,
    setShowWarningScreen,
    warningScreenContent,
    setWarningScreenContent,
    showSearch,
    setShowSearch,
    showPurchaseAgreement,
    setShowPurchaseAgreement,
    messageModal,
    setMessageModal,
    setErrorModalMessage,
    featureFlags,
    setFeatureFlags,
    sidebar,
    setSidebar,
    filterName,
    setFilterName,
    showShopModal,
    setShowShopModal,
    searchValue,
    setSearchValue,
    currentProductAndPlanData,
    setCurrentProductAndPlanData,
    userKitStatus,
    setUserKitStatus,
  };

  return <GlobalContext.Provider value={globalContext}>{children}</GlobalContext.Provider>;
};

import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { StoreProvider } from '@store/global-state';
import { client } from './apollo/client';
import { ApolloProvider } from 'react-apollo';
import { IntercomProvider } from 'react-use-intercom';
import StripeProvider from './utils/StripeProvider';
import SkeletonProvider from '@src/utils/providers/SkeletonProvider';

const INTERCOM_APP_ID = 'zumiegqg';

export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <StoreProvider>
    <ApolloProvider client={client}>
      <StripeProvider>
        <IntercomProvider appId={INTERCOM_APP_ID} initializeDelay={8000} autoBoot={true}>
          <SkeletonProvider>{element}</SkeletonProvider>
        </IntercomProvider>
      </StripeProvider>
    </ApolloProvider>
  </StoreProvider>
);

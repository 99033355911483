import gql from 'graphql-tag';
import { client } from '@src/apollo/client';
import { FetchPolicy } from 'apollo-boost';
import { subscriptionFragment } from '@queries/orders';
import {
  goalNutrientsInPackFragment,
  paymentMethodFragment,
  positiveFeedbackFragment,
  topNutrientStudiesFragment,
} from '@queries/fragments';

const goalFragment = gql`
  fragment goal on HealthProfile_Goal {
    id
    name
    color
    healthPillar
    score
    selected
    description
    imageUrl
    tagline
    userPopularityLabel
    nutrientClaims {
      nutrientLabel
      claim
      amount
      unit
    }
  }
`;

export const pillFragment = gql`
  fragment pill on HealthProfile_Pill {
    id
    name
    imageUrl
    nutrients {
      name
      amount
      unit
      ingredient
      nrv
    }
  }
`;

const userDetailsFragment = gql`
  fragment userDetails on UserDetails {
    firstName
    lastName
    email
    phone
    dob
    customerDataPlatformId
  }
`;

const referralInfoFragment = gql`
  fragment referralInfo on ReferralInfo {
    linkURL
    successfulReferrals
    commissions {
      total
      pending
      redeemed
    }
  }
`;

export const MAGIC_SIGN_IN = gql`
  mutation user_exchangeMagicLoginToken(
    $magicLinkToken: String!
    $leadAttribution: [KeyValueInput]
  ) {
    user_exchangeMagicLoginToken(
      magicLinkToken: $magicLinkToken
      leadAttribution: $leadAttribution
    ) {
      token
      customerDataPlatformId
    }
  }
`;

export const SEND_MAGIC_LINK = gql`
  mutation user_sendMagicLink(
    $email: String!
    $destination: String
    $leadAttribution: [KeyValueInput]
  ) {
    user_sendMagicLink(email: $email, destination: $destination, leadAttribution: $leadAttribution)
  }
`;

export const REGISTER_USER = gql`
  mutation user_register($email: String!, $firstName: String!, $leadAttribution: [KeyValueInput]) {
    user_register(email: $email, firstName: $firstName, leadAttribution: $leadAttribution) {
      token
      customerDataPlatformId
    }
  }
`;

export const USER_DETAILS = gql`
  query user_details {
    user_details {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`;

export const UPDATE_USER_DETAILS = gql`
  mutation user_updateDetails($firstName: String, $lastName: String, $phone: String) {
    user_updateDetails(firstName: $firstName, lastName: $lastName, phone: $phone) {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`;

export const UPDATE_USER_EMAIL = gql`
  mutation user_updateEmail($email: String!) {
    user_updateEmail(email: $email) {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`;

export const RECOMMENDED_PRODUCTS = gql`
  query recommended_products {
    health_recommendedProducts {
      id
      name
      updateAvailable
      subtype
      sku
      variant
      price
      currency
      isSubscribed
      pills {
        ...pill
      }
      action {
        value
      }
    }
  }
  ${pillFragment}
`;

export const DASHBOARD = gql`
  query dashboard {
    user_details {
      firstName
    }
    health_recommendedProducts {
      id
      sku
      quarterlySku
      variant
      name
      subtype
      description
      imageUrl
      score
      price
      currency
      action {
        type
        value
      }
    }
  }
`;

export const GOALS = gql`
  query health_goals {
    health_goals {
      ...goal
      issues {
        id
        name
        imageUrl
      }
      expert {
        name
        imageUrl
        title
      }
    }
    user_details {
      firstName
    }
  }
  ${goalFragment}
`;

export const SELECT_GOALS = gql`
  mutation user_selectGoals($ids: [String]) {
    user_selectGoals(ids: $ids) {
      ...goal
    }
  }
  ${goalFragment}
`;

export const GOAL_DETAILS = gql`
  query health_goalDetails($id: ID!) {
    user_details {
      firstName
    }
    health_goalDetails(id: $id) {
      id
      name
      imageUrl
      issues {
        id
        name
        imageUrl
      }
      foods {
        id
        name
        imageUrl
        description
      }
      dietAdvice {
        id
        description
        imageUrl
      }
      pills {
        ...pill
      }
      lifestyleAdvice {
        id
        description
        imageUrl
      }
    }
    health_recommendedProducts {
      sku
      variant
    }
  }
  ${pillFragment}
`;

export const SEND_FEEDBACK = gql`
  mutation user_sendFeedback($message: String!) {
    user_sendFeedback(message: $message)
  }
`;

export const GET_USER_REFERRALS = gql`
  query user_referrals {
    user_referrals {
      ...referralInfo
    }
  }
  ${referralInfoFragment}
`;

export const SET_REFERRER = gql`
  mutation user_referFriend($email: String!) {
    user_referFriend(email: $email)
  }
`;

export const EMAIL_PREFERENCES = gql`
  query get_email_preferences {
    user_subscribedToEmails
  }
`;

export const USER_ADD_ATTRIBUTION = gql`
  mutation user_addAttribution(
    $clientId: String
    $campaign: String
    $source: String
    $medium: String
    $content: String
    $term: String
  ) {
    user_addAttribution(
      clientId: $clientId
      campaign: $campaign
      source: $source
      medium: $medium
      content: $content
      term: $term
    )
  }
`;

export const CONTENT_SCREEN = gql`
  query content_getScreen($path: String!) {
    content_getScreen(path: $path) {
      title
      jsonStyle
      jsonSections
      canNavigateBack
    }
  }
`;

export const CONTENT_SUBMIT_FORM = gql`
  mutation user_submitForm($id: String!) {
    content_submitForm(id: $id) {
      title
      jsonStyle
      jsonSections
      canNavigateBack
      canDismiss
    }
  }
`;

export const KIT_CAN_REGISTER = gql`
  query kit_canRegister($type: String!, $barcode: String!) {
    kit_canRegister(type: $type, barcode: $barcode)
  }
`;

export const REGISTER_KIT = gql`
  mutation user_registerKit(
    $type: String!
    $barcode: String!
    $firstName: String!
    $lastName: String!
    $biologicalSex: String!
    $phone: String!
    $dob: Int!
  ) {
    user_registerKit(
      type: $type
      barcode: $barcode
      firstName: $firstName
      lastName: $lastName
      biologicalSex: $biologicalSex
      phone: $phone
      dob: $dob
    )
  }
`;

export const USER_COMMUNICATION_PREFERENCES = gql`
  query user_communicationPreferences {
    user_communicationPreferences {
      id
      title
      subtitle
      subscribed
      allowUnsubscribe
    }
    user_details {
      email
    }
  }
`;

export const USER_SUBSCRIBED_TO_MARKETING_EMAILS = gql`
  query user_subscribedToEmails {
    user_subscribedToEmails
  }
`;

export const USER_UPDATE_EMAIL_SUBSCRIPTION = gql`
  mutation user_updateEmailSubscription($enabled: Boolean!) {
    user_updateEmailSubscription(enabled: $enabled)
  }
`;

export const USER_UPDATE_COMMUNICATION_PREFERENCES = gql`
  mutation user_updateCommunicationPreferences($preferences: [UpdateCommunicationPreferencesData]) {
    user_updateCommunicationPreferences(preferences: $preferences) {
      id
      title
      subtitle
      subscribed
      allowUnsubscribe
    }
  }
`;

export const USER_WARNING_SCREEN = gql`
  query user_warningScreen {
    user_warningScreen {
      title
      jsonStyle
      jsonSections
      canNavigateBack
      canDismiss
      onLoad {
        type
        value
      }
      onDismiss {
        type
        value
      }
    }
  }
`;

export const USER_MARKETING_CONSENT = gql`
  query user_marketingConsent {
    user_marketingConsent {
      email
      sms
    }
  }
`;

export const USER_ADD_PAYMENT_METHOD = gql`
  mutation user_addPaymentMethod($token: String!, $setAsDefault: Boolean) {
    user_addPaymentMethod(token: $token, setAsDefault: $setAsDefault) {
      ...paymentMethod
    }
  }
  ${paymentMethodFragment}
`;

export const MIGRATE_LEGACY_PROFILE = gql`
  mutation health_migrateLegacyProfile {
    health_migrateLegacyProfile
  }
`;

export const PROMOTE_GUEST_CONSULTATION = gql`
  mutation chat_promoteGuest($guestId: String!, $variant: String!) {
    chat_promoteGuest(guestId: $guestId, variant: $variant)
  }
`;

export const USER_SIGN_OUT = gql`
  mutation user_signOut {
    user_signOut
  }
`;

export const USER_AUTH_DETAILS = gql`
  query user_authDetails {
    user_authDetails {
      token
      customerDataPlatformId
    }
  }
`;

export const USER_STRIPE_BALANCE = gql`
  query user_stripeBalance {
    user_stripeBalance
  }
`;

export const USER_KIT_STATUS = gql`
  query user_kitsStatus {
    user_kitsStatus {
      purchaseStatus {
        hasPurchasedDNAKit
        hasPurchasedBloodKit
      }
      kits {
        dna
        blood
      }
    }
  }
`;

export const IS_USER_ON_WAIT_LIST = gql`
  query user_isOnWaitlist($product: String!) {
    user_isOnWaitlist(product: $product)
  }
`;

export const USER_JOIN_WAIT_LIST = gql`
  mutation user_joinWaitlist($product: String!, $line: String!) {
    user_joinWaitlist(product: $product, line: $line)
  }
`;

export const getUserDetails = async (fetchPolicy: FetchPolicy = 'network-only') => {
  const { data: userDetailsData } = await client.query({
    query: USER_DETAILS,
    fetchPolicy,
  });
  return userDetailsData;
};

export const CONSULTATION_STATUS = gql`
  query user_consultationStatus {
    user_consultationStatus {
      status
    }
  }
`;

export const USER_CLAIMED_FREE_TRIALS = gql`
  query user_claimedFreeTrials {
    user_claimedFreeTrials {
      sku
      orderId
      purchaseDate
    }
  }
`;

export const DASHBOARD_UNIFIED = gql`
  query dashboard {
    health_goals {
      ...goal
      issues {
        id
        name
        imageUrl
      }
      expert {
        name
        imageUrl
        title
      }
    }
    user_subscriptions {
      ...subscription
    }
    user_hasAIConversation
  }
  ${goalFragment}
  ${subscriptionFragment}
`;

export const HEALTH_ATTRIBUTES = gql`
  query health_attributes {
    health_attributes {
      id
      name
      score
      status
      value
    }
  }
`;

export const HEALTH_RESULTS = gql`
  query health_results($productType: String!) {
    health_results(productType: $productType) {
      ...positiveFeedback
      ...goalNutrientsInPack
      ...topNutrientStudies
    }
  }
  ${positiveFeedbackFragment}
  ${goalNutrientsInPackFragment}
  ${topNutrientStudiesFragment}
`;

export const HAS_AI_CONVERSATION = gql`
  query user_hasAIConversation {
    user_hasAIConversation
  }
`;
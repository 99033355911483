import { isSSR } from '@services/global';
import { STORAGE_KEYS } from '@constants/storage';

export const setSessionStorageItem = (key: keyof typeof STORAGE_KEYS, value: string) => {
  if (isSSR) return null;
  return sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorageItem = (key: keyof typeof STORAGE_KEYS) => {
  if (isSSR) return null;
  const storageItem = sessionStorage.getItem(key);
  if (storageItem) return JSON.parse(storageItem);
};

export const removeSessionStorageItem = (key: keyof typeof STORAGE_KEYS) => {
  if (isSSR) return null;
  sessionStorage.removeItem(key);
};

export const setLocalStorageItem = (key: keyof typeof STORAGE_KEYS, value: string) => {
  if (isSSR) return null;
  return localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key: keyof typeof STORAGE_KEYS) => {
  if (isSSR) return null;
  const storageItem = localStorage.getItem(key);
  if (storageItem) return JSON.parse(storageItem);
};

export const removeLocalStorageItem = (key: keyof typeof STORAGE_KEYS) => {
  if (isSSR) return null;
  localStorage.removeItem(key);
};

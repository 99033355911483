/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { setCookie } from './src/services/cookies';
import { PRODUCT_COOKIES } from './src/constants';
import { COOKIE_EXPIRES_IN } from './src/constants/cookies';
import { updateSessionIdCookie } from './src/services/session';

const activateOptimize = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'optimize.activate' });
};

const checkUtmParams = location => {
  const urlParams = new URLSearchParams(location.search);
  urlParams.forEach((value, key) => {
    if (key === 'offer') sessionStorage.setItem(key, value);
    if (
      key.startsWith('utm_') ||
      key === PRODUCT_COOKIES.ESSENTIAL_ONE.name ||
      key === PRODUCT_COOKIES.PERSONALISED_PLUS.name
    ) {
      setCookie(key, value, COOKIE_EXPIRES_IN.maxAge);
    }
    if (key === 'awc') {
      setCookie('awinAWC', value, COOKIE_EXPIRES_IN.oneYear);
    }
  });
};

const checkSessionId = location => {
  const urlParams = new URLSearchParams(location.search);
  const sessionId = urlParams.get('sessionId');
  if (sessionId) {
    updateSessionIdCookie(sessionId);
  }
};

export { wrapRootElement } from './src/wrapRootElement.tsx';

export const onRouteUpdate = ({ location }) => {
  activateOptimize();
  checkUtmParams(location);
  checkSessionId(location);
};

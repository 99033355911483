import { COOKIES, COOKIE_EXPIRES_IN } from '@constants/cookies';
import { v4 as uuidv4 } from 'uuid';
import { getCookie, setCookie } from './cookies';

export const updateSessionIdCookie = (sessionId: string) =>
  setCookie(COOKIES.trackingSessionId, sessionId, COOKIE_EXPIRES_IN.halfAnHour);

export const getSessionId = () => {
  const sessionId = getCookie(COOKIES.trackingSessionId) || uuidv4();
  updateSessionIdCookie(sessionId);
  return sessionId;
};

import gql from 'graphql-tag';

export const ORDER = gql`
  query user_order($id: ID!) {
    user_order(id: $id) {
      id
      orderNumber
      date
      type
      status
      statusLabel
      itemCount
      currency
      subTotal
      discountAmount
      discountCode
      shipping
      total
      awinParts
      awinTotal
      items {
        id
        sku
        name
        qty
        allowQuantityChange
        singleItemCost
        rowTotal
        rowTotalWithDiscount
        description
        image
      }
    }
  }
`;

export const ORDERS = gql`
  query user_orders {
    user_orders {
      id
      orderNumber
      date
      type
      status
      statusLabel
      itemCount
      currency
      subTotal
      discountAmount
      discountCode
      shipping
      total
      items {
        id
        sku
        name
        qty
        cost
        description
        image
      }
    }
  }
`;

export const subscriptionFragment = gql`
  fragment subscription on Subscription {
    id
    product {
      id
      sku
      name
      price
      currency
      imageUrl
      rrp
    }
    quantity
    deliveryAddress {
      firstName
      lastName
      street
      city
      postcode
      countryId
      phone
    }
    status
    restartsAt
    frequency
    currentPeriodStart
    currentPeriodEnd
    nextDeliveryDate
    hasLegacyPrice
    canOfferCancellationDiscount
  }
`;

export const SUBSCRIPTIONS = gql`
  query user_subscriptions {
    user_subscriptions {
      ...subscription
    }
  }
  ${subscriptionFragment}
`;

export const PAUSE_SUBSCRIPTION = gql`
  mutation subscription_pause($id: ID!, $restartAt: String!) {
    subscription_pause(id: $id, restartAt: $restartAt) {
      ...subscription
    }
  }
  ${subscriptionFragment}
`;

export const UNPAUSE_SUBSCRIPTION = gql`
  mutation subscription_unpause($id: ID!) {
    subscription_unpause(id: $id) {
      ...subscription
    }
  }
  ${subscriptionFragment}
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation subscription_cancel($id: ID!, $feedback: [String], $additionalFeedback: String) {
    subscription_cancel(id: $id, feedback: $feedback, additionalFeedback: $additionalFeedback) {
      ...subscription
    }
  }
  ${subscriptionFragment}
`;

export const UPDATE_SUBSCRIPTION_SHIPPING_ADDRESS = gql`
  mutation subscription_updateShippingAddress(
    $subscriptionId: ID!
    $firstName: String
    $lastName: String
    $street: String
    $city: String
    $regionId: String
    $postcode: String
    $countryCode: String
    $phone: String
  ) {
    subscription_updateShippingAddress(
      subscriptionId: $subscriptionId
      firstName: $firstName
      lastName: $lastName
      street: $street
      city: $city
      regionId: $regionId
      postcode: $postcode
      countryCode: $countryCode
      phone: $phone
    ) {
      ...subscription
    }
  }
  ${subscriptionFragment}
`;

export const SUBSCRIPTION_CLAIM_CANCELLATION_OFFER = gql`
  mutation subscription_claimCancellationOffer($id: ID!) {
    subscription_claimCancellationOffer(id: $id) {
      ...subscription
    }
  }
  ${subscriptionFragment}
`;

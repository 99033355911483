import { navigate } from 'gatsby';
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import * as Sentry from '@sentry/gatsby';
import { v4 as uuidv4 } from 'uuid';
import { getCookie } from '@services/cookies';
import { logout } from '@services/auth';
import { getCurrency } from '../utils/currency';
import { COOKIES } from '@constants/cookies';
import { GraphQLError } from 'graphql';
import { GRAPH_QL_ERROR_TYPES } from '../constants';
import { STORAGE_KEYS } from '@constants/storage';
import { getSessionStorageItem } from '../utils/storage';
import { isSSR } from '@src/services/global';

interface TypedGraphQLError extends GraphQLError {
  errorType: GRAPH_QL_ERROR_TYPES;
}

const getToken = () => {
  const token = getCookie(COOKIES.token);
  return token ? token : '';
};

const diagnosticsCookie: string | undefined = getCookie(COOKIES.vitlDiagnostics);

const traceToken = getSessionStorageItem(STORAGE_KEYS.traceToken);

export const client = new ApolloClient({
  uri: process.env.GATSBY_APP_SYNC_URL,
  fetch,
  request: operation => {
    const token = getToken();
    const currency = getCurrency();

    const context = {
      headers: {
        'x-api-key': process.env.GATSBY_APP_SYNC_KEY,
        authorization: token,
        'x-store-code': currency,
      },
    };

    const diagnosticsHeaderValue = {
      requestId: uuidv4(),
      ...(traceToken && { traceToken }),
      ...(diagnosticsCookie && JSON.parse(atob(diagnosticsCookie))),
    };

    Object.assign(context.headers, {
      'x-vitl-diagnostics': btoa(JSON.stringify(diagnosticsHeaderValue)),
    });

    operation.setContext(context);
  },
  onError: ({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const typedError = error as TypedGraphQLError;
        const { message, locations, path, errorType } = typedError;

        const privateRoutes = ['/account', '/kit'];
        const isPrivateRoute = privateRoutes.some(route => window.location.pathname.startsWith(route));

        if (errorType === GRAPH_QL_ERROR_TYPES.Unauthorized) {
          logout();
          if (isPrivateRoute) {
            navigate('/account/signin');
          // TODO: this currently causes endless logout loop, need to prevent useFreeTrial from being called for guest
          // } else if (!isSSR) {
          //   window.location.reload();
          }
        } else {
          Sentry.captureException(
            new Error(`[GraphQL error]: Path: ${path}, Message: ${message}, Location: ${locations}`),
            {
              extra: {
                message,
                locations,
                path,
              },
            }
          );
        }
      });
    }
    if (networkError) {
      Sentry.captureException(networkError);
    }
  },
});

import { getCookie, setCookie, removeCookie } from '@services/cookies';
import { client } from '../apollo/client';
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';
import { USER_SIGN_OUT } from '@queries/user';

export const setToken = (token: string) => {
  setCookie(COOKIES.token, token, COOKIE_EXPIRES_IN.thirtyDays);
};

export const removeToken = () => {
  removeCookie(COOKIES.token);
  removeCookie(COOKIES.guestToken);
};

export const getToken = () => {
  const token = getCookie(COOKIES.token);
  return !!token;
};

export const logout = async () => {
  removeToken();
  removeCookie(COOKIES.basketId);
  await client.mutate({
    mutation: USER_SIGN_OUT,
  });
  client.clearStore();
};

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://vitl.com","noQueryString":true,"noTrailingSlash":true},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VITL","short_name":"VITL","start_url":"/","background_color":"#ffd326","theme_color":"#ffd326","display":"standalone","icon":"src/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"428d30bc315a3e3330b4828ffac73e9a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MSH5P7","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"qZ5l_OqIsMkSViP8ILdEVw","gtmPreview":"env-2","enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"24pZqeh5A9n0H1JqFdSq17jr4lC","trackPage":false,"trackPageDelay":50,"dataPlaneUrl":"https://p-rudderstack.vitl.com","delayLoad":false,"manualLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

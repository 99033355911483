import { getCookie } from './cookies';
import { ICountry } from '@interfaces/address';
import { OFFER_CODES } from '@constants/enums';
import { PRODUCT_COOKIES } from '../constants';
import { COOKIES } from '@constants/cookies';

declare global {
  interface Window {
    rudderanalytics: any;
    zE: any;
    zdonload: any;
  }
}

export const isEmpty = (obj: any) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const checkFreeTrial = (
  product: string,
  partnership: string
): {
  productCode: string;
  isActive: boolean;
} => {
  const trialData = {
    productCode: '',
    isActive: false,
  };
  if (product === 'essentialOne' && partnership !== undefined) {
    trialData.isActive = [
      'generic',
      'essential-one-free-delivery',
      'essential-one-save-30',
      PRODUCT_COOKIES.ESSENTIAL_ONE.value.TRIAL,
      PRODUCT_COOKIES.ESSENTIAL_ONE.value.ESSENTIAL_ONE_20_OFF_FREE_TRIAL,
      PRODUCT_COOKIES.ESSENTIAL_ONE.value.AFFILIATES,
      PRODUCT_COOKIES.ESSENTIAL_ONE.value.FREE_TRIAL,
      PRODUCT_COOKIES.ESSENTIAL_ONE.value.REFERRAL,
      'essential-one-save-50',
    ].some(cookieValue => cookieValue === partnership);
    trialData.productCode = OFFER_CODES.EssentialOne;
    return trialData;
  }

  if (product === 'personalisedPlus' && partnership !== undefined) {
    trialData.productCode = OFFER_CODES.PersonalisedPlus;
    trialData.isActive = partnership === 'free-trial';
    return trialData;
  }

  return trialData;
};

export const isUsingGBPCurrency = () => String(getCookie(COOKIES.currency)).toUpperCase() === 'GBP';

export const getCountryNameById = (countries: Array<ICountry>, countryId: string) => {
  const countryName =
    countryId === 'UK' ? 'United Kingdom' : countries?.find((country: ICountry) => country.id === countryId)?.name;

  return countryName || countryId;
};

export const areDatesEqual = (a: any, b: any) => {
  const dateA = new Date(a);
  const dateB = new Date(b);
  dateA.setHours(0, 0, 0, 0);
  dateB.setHours(0, 0, 0, 0);
  if (dateA.getTime() && dateB.getTime()) {
    return dateA.getTime() === dateB.getTime();
  }
  return false;
};

export const isSSR = typeof window === 'undefined';

export const isElementLoaded = (selector: string) => {
  return new Promise((resolve, reject) => {
    const observer = new MutationObserver((mutations, obs) => {
      let el = document.querySelector(selector);
      if (el) {
        resolve(el);
        obs.disconnect();
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  });
};

export enum SIDEBAR_VIEWS {
  empty = '',
  navigation = 'navigation',
  filters = 'filters',
}

export const SIDEBAR_ANIMATION = {
  closed: {
    width: 0,
    overflow: 'hidden',
    transition: { type: 'linear', bounce: 0, duration: 0.4 },
  },
  open: {
    width: 300,
    overflow: 'auto',
    transition: { type: 'spring', bounce: 0, duration: 0.4 },
  },
};

export const SIDEBAR_INNER_ANIMATION = {
  closed: {
    opacity: 0,
    height: 0,
    transition: { type: 'spring', delay: 0, duration: 0.3 },
  },
  open: {
    opacity: 1,
    height: '100%',
    transition: { type: 'spring', delay: 0.2, duration: 0.2 },
  },
};
